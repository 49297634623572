<template>
  <div>
    <b-modal
      id="modal-user"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      title="User"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="name"
            >
              <b-form-input
                v-model.trim="myObj.name"
                placeholder="Enter name"
                @focusout="CheckName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Email"
              invalid-feedback="Email is required."
              ref="email"
            >
              <b-form-input
                v-model.trim="myObj.email"
                placeholder="Enter email"
                @focusout="CheckEmail()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Password"
              invalid-feedback="Password is required."
              ref="pass"
            >
              <b-input-group class="input-group-merge">
                <!-- :class="errors.length > 0 ? 'is-invalid' : null" -->
                <!-- :state="errors.length > 0 ? false : null" -->
                <b-form-input
                  v-model="myObj.passwordDec"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                  @focusout="CheckPass()"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="User Rights"
              invalid-feedback="User Rights is required."
              ref="rights"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="selectedRights"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rightsOptions"
                label="title"
                :clearable="false"
                placeholder="select rights"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="text-right">
            <b-button variant="success" @click="saveUser()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-card no-body class="mb-0">
      <b-row class="p-2">
        <b-col
          class="d-md-flex align-items-center justify-content-start"
          style="gap: 1.5rem"
        >
          <b-button @click="OpenAddModal(0)" variant="primary">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Add User</span>
          </b-button>
          <b-form-input
            style="flex: 1;"
            v-model="searchQuery"
            class="d-inline-block mt-1 mt-md-0"
            placeholder="Search by name or email"
            autocomplete="off"
          />
        </b-col>
      </b-row>
      <b-table
        :items="filters"
        :fields="fields"
        show-empty
        responsive
        :busy="dataLoading"
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(actions)="data">
          <b-button
            v-if="data.item.status == 'active'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon mr-1"
            v-b-tooltip.hover.v-danger
            v-b-tooltip.placement.left
            title="Suspend"
            size="16"
            @click="changeStatus(data.item, 'suspend')"
          >
            <feather-icon size="16" icon="XIcon" />
          </b-button>

          <b-button
            v-else
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="btn-icon mr-1"
            v-b-tooltip.hover.v-success
            v-b-tooltip.placement.left
            title="Active"
            size="16"
            @click="changeStatus(data.item, 'active')"
          >
            <feather-icon size="16" icon="CheckIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            v-b-tooltip.hover.v-primary
            v-b-tooltip.placement.right
            title="Edit"
            @click="OpenAddModal(data.item)"
          >
            <feather-icon size="16" icon="EditIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormGroup,
    BFormTextarea,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      items: [],
      fields: [
        { label: "name", key: "name" },
        { label: "email", key: "email" },
        { label: "status", key: "status" },
        { label: "actions", key: "actions" },
      ],
      searchQuery: "",
      myObj: {
        id: 0,
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        role: "admin",
        package: "",
        status: "active",
        isApproved: true,
        approvedBy: 0,
        createdOn: "",
        userRights: "",
        passwordDec: "",
      },
      request: false,
      rightsOptions: [
        {
          title: "Menu",
          route: "apps-menu",
          icon: "MenuIcon",
        },
        {
          title: "Sub menu",
          route: "apps-submenu",
          icon: "GridIcon",
        },
        {
          title: "Mega menu",
          route: "apps-megamenu",
          icon: "LayoutIcon",
        },
        {
          title: "Pages",
          route: "apps-pages",
          icon: "FileTextIcon",
        },
        {
          title: "Projects",
          route: "apps-projects",
          icon: "FlagIcon",
        },
        {
          title: "Blogs",
          route: "apps-blogs",
          icon: "BookmarkIcon",
        },
        {
          title: "Fundraiser",
          route: "apps-fundraiser",
          icon: "TrendingUpIcon",
        },
        {
          title: "Partners",
          route: "apps-partners",
          icon: "ThumbsUpIcon",
        },
        {
          title: "Priorities",
          route: "apps-priorities",
          icon: "ListIcon",
        },
        {
          title: "Footer",
          route: "apps-footer",
          icon: "InfoIcon",
        },
        {
          title: "Transactions",
          route: "apps-transactions",
          icon: "CreditCardIcon",
        },
        {
          title: "Bank Details",
          route: "apps-bank",
          icon: "CreditCardIcon",
        },
        // {
        //   title: "User Management",
        //   route: "apps-users",
        //   icon: "UsersIcon",
        // },
      ],
      selectedRights: [],
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    filters: function() {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.email.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  methods: {
    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          name: "",
          email: "",
          password: "",
          phone: "",
          address: "",
          role: "admin",
          package: "",
          status: "active",
          isApproved: true,
          approvedBy: 0,
          createdOn: new Date().toJSON(),
          userRights: "",
          passwordDec: "",
        };
        this.selectedRights = [];
      } else {
        this.myObj = { ...id };
        this.selectedRights = JSON.parse(this.myObj.userRights);
      }
      this.$bvModal.show("modal-user");
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmail() {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let elem = this.$refs["email"];
      return (elem.state = re.test(this.myObj.email));
    },
    CheckPass() {
      var elem = this.$refs["pass"];
      if (this.myObj.passwordDec == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRights() {
      // console.log(this.selectedRights);
      var elem = this.$refs["rights"];
      if (this.selectedRights.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    saveUser() {
      this.CheckName();
      this.CheckEmail();
      this.CheckPass();
      this.CheckRights();
      if (
        this.CheckName() == false ||
        this.CheckEmail() == false ||
        this.CheckPass() == false ||
        this.CheckRights() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        // console.log(this.selectedRights);
        this.myObj.userRights = JSON.stringify(this.selectedRights);
        console.log(this.myObj);
        var config = {
          method: "post",
          url: "https://api.worldappeal.org.uk/api/Users/SaveUser",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.myObj,
        };

        axios(config)
          .then((res) => {
            // console.log(res.data);
            if (res.data.status === "success") {
              if (res.data.data == "exist") {
                this.$bvToast.toast(this.myObj.email + " already exist!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("User saved successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.LoadData();
                this.$bvModal.hide("modal-user");
              }
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch(function(error) {
            this.$bvToast.toast("Something went wrong!", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
            console.log(error);
          })
          .finally(() => (this.request = false));
      }
    },
    changeStatus(item, val) {
      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${val} the user?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          item.status = val;
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Users/" + item.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: item,
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                this.LoadData();
                this.$bvToast.toast("User is " + item.status + " now!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Users/LoadAdmin",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.items = res.data.data.filter((el) => el.id != 1);
          // this.items = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style></style>
